import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth/auth.guard';
import { PractitionerInfoGuard } from 'src/app/guards/practitioner-info/practitioner-info.guard';
import { DesktopGuard } from '../../guards/desktop-guard/desktop.guard';

export const practitionerRoutes: Routes = [
  {
    path: 'practitioner',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':practitionerId',
        canActivate: [PractitionerInfoGuard],
        children: [
          { path: '', loadComponent: () => import('./practitioner/practitioner.page').then((m) => m.PractitionerPage) },
          { path: 'informations', loadComponent: () => import('./practitioner-info/practitioner-info.page').then((m) => m.PractitionerInfoPage) },
          { path: 'professions', loadComponent: () => import('./practitioner-professions/practitioner-professions.page').then((m) => m.PractitionerProfessionsPage) },
          { path: 'certifications', loadComponent: () => import('./practitioner-certifications/practitioner-certifications.page').then((m) => m.PractitionerCertificationsPage) },
          { path: 'formations', loadComponent: () => import('./practitioner-formations/practitioner-formations.page').then((m) => m.PractitionerFormationsPage) },
          { path: 'experiences', loadComponent: () => import('./practitioner-experiences/practitioner-experiences.page').then((m) => m.PractitionerExperiencesPage) },
          { path: 'user-access', loadComponent: () => import('../user-access/user-access.page').then((m) => m.UserAccessPage) },
          {
            path: '**',
            redirectTo: '',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: 'practitioners',
    canActivate: [AuthGuard, DesktopGuard],
    loadComponent: () => import('./practitioners/practitioners.page').then((m) => m.PractitionersPage),
  },
];
