import { Routes } from '@angular/router';
import { AuthGuard } from '../../guards/auth/auth.guard';

export const missionTabsRoutes: Routes = [
  {
    path: 'missions',
    loadComponent: () => import('./missions/missions.page').then((m) => m.MissionsPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'mission/:id/boost',
    loadComponent: () => import('./boost-mission/boost-mission.page').then(m => m.BoostMissionPage),
    canActivate: [AuthGuard],
  },
];
